import React, { useEffect, useState } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc, Timestamp, query, orderBy, limit, startAfter } from "firebase/firestore";
import { Input, Button } from 'antd';
import { format } from 'date-fns';
import { Helmet } from 'react-helmet';

import styles from './App.module.scss';

function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyD_C7SWQidk79i2V79lTJ6J8EM3u6Z8ytk",
    authDomain: "sample-react-firebase-app.firebaseapp.com",
    projectId: "sample-react-firebase-app",
    storageBucket: "sample-react-firebase-app.firebasestorage.app",
    messagingSenderId: "1033430379628",
    appId: "1:1033430379628:web:d018d0a6e6dac624327c49",
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [comment, setComment] = useState([]); 
  const [commentName, setCommentName] = useState(""); 
  const [secretMessageVisible, setSecretMessageVisible] = useState(false);
  const [lastVisible, setLastVisible] = useState(null); // Для хранения последней видимой записи
  const [hasMore, setHasMore] = useState(true); // Проверка, есть ли еще данные для загрузки

  const getData = async () => {
    try {
      let q = query(collection(db, "comments"), orderBy("createdAt", "desc"), limit(5)); // Ограничиваем 5 записями на раз

      if (lastVisible) {
        // Если есть последняя загруженная запись, используем startAfter для пагинации
        q = query(q, startAfter(lastVisible));
      }

      const querySnapshot = await getDocs(q);
      const commentArray = [];
      
      querySnapshot.forEach((doc) => {
        commentArray.push({ id: doc.id, ...doc.data() });
      });

      // Если записей меньше 5, значит пагинация закончена
      setHasMore(querySnapshot.docs.length === 5);

      // Обновляем состояние для следующих загрузок
      setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

      // Добавляем новые записи в список
      setComment(prevComment => [...prevComment, ...commentArray]);
    } catch (e) {
      console.error("Ошибка при получении данных: ", e);
    }
  };

  const addComment = async () => {
    if (commentName.trim() === "") {
      return;
    }

    try {
      await addDoc(collection(db, "comments"), {
        text: commentName,
        createdAt: Timestamp.fromDate(new Date()),
        likes: 0 
      });

      setCommentName("");
      getData(); // После добавления новой заметки загружаем данные
    } catch (e) {
      console.error("Ошибка при добавлении документа: ", e);
    }
  };

  const deleteComment = async (id) => {
    try {
      const docRef = doc(db, "comments", id);
      await deleteDoc(docRef);
      getData(); // После удаления обновим список заметок
    } catch (e) {
      console.error("Ошибка при удалении документа: ", e);
    }
  };

  useEffect(() => {
    getData(); // Загружаем данные при монтировании компонента
  }, []);

  useEffect(() => {
    if (commentName.toLowerCase().includes("rgadmin")) {  
      setSecretMessageVisible(true);  
    } else {
      setSecretMessageVisible(false);  
    }
  }, [commentName]);  

  const { TextArea } = Input;

  return (
    <div className={styles.container}>
      <Helmet>
        <link rel="icon" href="" />
        <title>Анонимные заметки</title>
        <meta name="description" content="Анонимные заметки, делитесь своим мнением, событиями, переживаниями!" />
        <meta name="keywords" content="заметки, заметки приложение, добавление заметок, комментарии, анонимно, анонимные заметки" />
      </Helmet>
      <h1>Заметки</h1>

      <TextArea 
        placeholder="что угодно" 
        autoSize 
        value={commentName}
        onChange={(e) => setCommentName(e.target.value)}
        style={{
          maxWidth: '70%',
          textAlign: 'center',
          padding: '20px'
        }}
      />

      <div style={{ margin: '24px 0' }} />
      <Button type="primary" onClick={addComment}>добавить заметку</Button>

      <ul>
        {comment.map((comm) => (
          <li key={comm.id}>
            <h2>{comm.text}</h2>
            <h3>
              {comm.createdAt instanceof Timestamp
                ? format(comm.createdAt.toDate(), 'HH:mm:ss dd.MM')
                : "Дата не доступна"}
            </h3>
            {secretMessageVisible && (
              <Button 
                type="dashed" 
                className={styles.myButton} 
                onClick={() => deleteComment(comm.id)}
              >
                удалить
              </Button>
            )}
            <hr />
          </li>
        ))}
      </ul>

      {hasMore && (
        <Button onClick={getData} type="primary">
          Загрузить еще
        </Button>
      )}
    </div>
  );
}

export default App;
